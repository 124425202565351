import React from "react"
import { Helmet } from "react-helmet"

export default function Process({ location }) {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="Description" content="Lance – Process"></meta>

        <title>Lance / Play</title>
        <link rel="preload" href="/fonts/fonts.css" as="style"></link>
        <body className="scrollSmooth" />
      </Helmet>

      <>
        <div className=" pb-24">
          <h1 className="my-14 text-center font-display italic font-light text-5xl">Values</h1>
          {/* <p className="text-base mb-4 max-w-xl">
            Values are the guiding light to succesful work.
          </p> */}
          <div className="">
            <div className="grid md:grid-cols-12 mb-12">
              <div className="col-span-5">
                <h2 className="font-light text-2xl  md:mr-12">
                  Stay open
                </h2>
              </div>
              <div className="col-span-7 pt-2">
                <p className="mb-4 text-lg">
                  The moment you get too attached to one point of view so many
                  other opportunies close down.
                </p>
                <p className="mb-4 text-lg">
                  Generally it's best to stay open to all opportunities before narrowing down.  
                </p>
                
              </div>
            </div>
            <div className="grid md:grid-cols-12 mb-12">
              <div className="col-span-5">
                <h2 className="font-light text-2xl  md:mr-12">
                  Start from scratch
                </h2>
              </div>
              <div className="col-span-7 pt-2">
              <p className="mb-4 text-lg">
                  While it's good to have relevant references before going into
                  a project, sometimes it's best to just look at what you have,
                  explore and iterate untill you have something that is distinctive, and that looks more like
                  you.
                </p>
                
              </div>
            </div>
            <div className="grid md:grid-cols-12 mb-12">
              <div className="col-span-5">
                <h2 className="font-light text-2xl  md:mr-12">
                  Don't be afraid to push back
                </h2>
              </div>
              <div className="col-span-7 pt-2">
                <p className="mb-4 text-lg">
                  Sometimes pushing back is necessary to lead you into the best outcome. 
                </p>
                <p className="mb-4 text-lg">
                  Let's imagine, Brand Design should be objective first with a bit of subjectivity 
                </p>
              </div>
            </div>
            
            <div className="grid md:grid-cols-12 mb-12">
              <div className="col-span-5">
                <h2 className="font-light text-2xl  md:mr-12">Figure it out</h2>
              </div>
              <div className="col-span-7 pt-2">
                <p className="mb-4 text-lg">
                  Brand design is abstract. It's hard to articule into words what you want </p>
                <p className="mb-4 text-lg">
                  You are in good luck, I am here to transform into a concrete
                  thing.
                </p>
              </div>
            </div>
            
          
          </div>
          <div>
          <div class="relative flex overflow-x-hidden">
  <div class="py-12 animate-marquee whitespace-nowrap">
    <span class="mx-6 text-3xl">Create something remarkble</span>
    <span class="mx-6 text-3xl">The devil is in the details</span>
    <span class="mx-6 text-3xl">You, but in your best version</span>
    <span class="mx-6 text-3xl">Holistic approach for the win</span>
  </div>

  <div class="absolute top-0 py-12 animate-marquee2 whitespace-nowrap">
    <span class="mx-6 text-3xl">Create something remarkble</span>
    <span class="mx-6 text-3xl">The devil is in the details</span>
    <span class="mx-6 text-3xl">You, but in your best version</span>
    <span class="mx-6 text-3xl">Holistic approach for the win</span>
  </div>
</div>
          </div>
        </div>
      </>
    </>
  )
}
